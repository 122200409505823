import React, { useState, useRef, useEffect } from "react";
import { gsap } from 'gsap'
import "../css/NavigationBar.css";

const NavigationBar = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [navHeight, setNavHeight] = useState("0px");
  const [barColor, setBarColor] = useState("white");
  const navBar = useRef(null)

  useEffect(() => {
    gsap.fromTo(
      navBar.current,
      { 
        opacity: 0
      },
      {
        opacity: 1,
        duration: 1.4,
        ease: 'back',
        delay: 0.5
      }
    )
  }, [])

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    if (showMenu) {
      setNavHeight("0px");
      setBarColor("white");
    } else {
      setNavHeight("20rem");
      setBarColor("black");
    }
    document.querySelector(".nav-options").style.height = navHeight;
  };

  const handleOptionClick = (option) => {
    if (showMenu) {
      toggleMenu();
    }
    props.onOptionSelect(option);
  };

  const lift = (event) => {
    event.target.style.transform = "translateY(-5px)";
    setTimeout(() => {
      event.target.style.transform = "translateY(0px)";
    }, 300);
  };

  return (
    <div ref={navBar} className="nav-bar">
      <div style={{ fontWeight: "600", fontSize: "23px", cursor: "default" }}>
        <span onMouseEnter={lift}>C</span>
        <span onMouseEnter={lift}>l</span>
        <span onMouseEnter={lift}>a</span>
        <span onMouseEnter={lift}>i</span>
        <span onMouseEnter={lift}>r</span>
        <span onMouseEnter={lift}>e</span>
        &nbsp;
        <span onMouseEnter={lift}>R</span>
        <span onMouseEnter={lift}>h</span>
        <span onMouseEnter={lift}>o</span>
        <span onMouseEnter={lift}>d</span>
        <span onMouseEnter={lift}>a</span>
      </div>

      <div onClick={toggleMenu} className="menu-icon">
        <div style={{ backgroundColor: barColor }} className="bar"></div>
        <div style={{ backgroundColor: barColor }} className="bar"></div>
        <div style={{ backgroundColor: barColor }} className="bar"></div>
      </div>
      <ul className="nav-options" style={{ height: navHeight }}>
        <li
          onClick={() => handleOptionClick("Projects")}
          style={{ marginTop: "5rem" }}
        >
          Projects
        </li>
        <li onClick={() => handleOptionClick("About")}>About</li>
        <li
          onClick={() => handleOptionClick("Contact Me")}
          style={{ fontWeight: "700" }}
        >
          Contact Me
        </li>
      </ul>
      <ul className="nav-options-desktop">
        <li
          id="hover1"
          onClick={() => handleOptionClick("Projects")}
          style={{ marginRight: "20px" }}
        >
          Projects
        </li>
        <li id="hover1" onClick={() => handleOptionClick("About")}>
          About
        </li>
        <li onClick={() => handleOptionClick("Contact Me")} id="contact-btn">
          Contact Me
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
