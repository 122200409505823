import "../css/About.css";
import profile from "../images/profile.png";
import React, { useLayoutEffect } from 'react';

import { gsap } from "gsap";

const About = () => {
  useLayoutEffect(() => {
    import("gsap/ScrollTrigger").then(({ ScrollTrigger }) => {
      gsap.registerPlugin(ScrollTrigger);
      gsap.to(".box1", {
        opacity: 1,
        scrollTrigger: {
          trigger: ".box1",
          start: "top bottom",
          end: "top 20%",
          scrub: true,
          immediateRender: true,
        }
      });
    });
  }, []);

  return (
    <>
      <div style={{ paddingTop: "20vh" }}>
        <h2 className="title">About</h2>
        <div className="about-row" >
          <div className="box1" id="about-text">
            Software Engineer and AWS Certified Cloud
            Practitioner based in Orange County. Over the years, I have
            specialized in creating websites and applications that provide an
            excellent user experience. I enjoy the challenge of taking a
            client's vision and bringing it to life through code.
            {"\n\n"}
            As an AWS Cloud Practitioner, I have experience deploying websites
            on the cloud to ensure they are scalable, reliable, and secure. If
            you're are looking for a developer with these qualities, please feel
            free to get in touch with me by filling out the form below.
          </div>
          <div className="box1" id="about-img-box">
            <img id="about-img" src={profile} alt="Claire Rhoda profile"></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
