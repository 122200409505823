import "../css/Header.css";
import SplitType from "split-type";
import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";

const Header = (props) => {
  const h1Ref = useRef(null);
  const pRef = useRef(null);
  const buttonRef = useRef(null);
  const arrowRef = useRef(null);

  useEffect(() => {
    const h1 = new SplitType(h1Ref.current);

    gsap.fromTo(
      h1.words,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.03,
        duration: 1.5,
        ease: "back",
      }
    );

    // gsap.fromTo(
    //   p.lines,
    //   {
    //     y: 100,
    //     opacity: 0,
    //   },
    //   {
    //     y: 0,
    //     opacity: 1,
    //     stagger: 0.1,
    //     duration: 0.75,
    //     ease: "back",
    //   }
    // );

    // gsap.fromTo(
    //   buttonRef.current,
    //   {
    //     y: 100,
    //     opacity: 0,
    //   },
    //   {
    //     y: 0,
    //     opacity: 1,
    //     duration: 0.75,
    //     ease: "back",
    //   }
    // );

    gsap.fromTo(
      arrowRef.current,
      { 
        opacity: 0
      },
      {
        opacity: 1,
        duration: 1,
        ease: 'back',
        delay: 1
      }
    );
  }, []);

  const handleOptionClick = () => {
    props.onOptionSelect("Projects");
  };

  const [arrowDisplay, setArrowDisplay] = useState("flex");

  useEffect(() => {
    const handleScroll = () => {
      setArrowDisplay("none");
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleClick() {
    window.scrollBy({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  }
  return (
    <div>
      <header>
        <h1 ref={h1Ref}>
          Experienced Software Developer with a Passion for Clean, Efficient
          Code
        </h1>
        <p ref={pRef}>
          Expertise in Website Development and Cloud Deployment for Seamless
          Online Access
        </p>
        <button
          onClick={() => handleOptionClick()}
          ref={buttonRef}
          id="view-proj-btn"
        >
          View Projects<div style={{ width: "20px" }}></div>
          <svg
            id="arrow-right"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </button>
      </header>
      <div
        ref={arrowRef}
        style={{ display: arrowDisplay }}
        id="scroll-down-arrow"
        onClick={handleClick}
      >
        ^
      </div>
    </div>
  );
};

export default Header;
