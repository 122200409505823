import "../css/Projects.css";
import project1 from "../images/proj1.png";
import project2 from "../images/proj2.png";
import project3a from "../images/proj3a.png";
import project3b from "../images/proj3b.png";
import project4 from "../images/proj4.png";
import project5a from "../images/proj5a.png";
import project5b from "../images/proj5b.png";
import project6 from "../images/proj6.png";
import React, { useLayoutEffect } from "react";

const Projects = () => {
  useLayoutEffect(() => {
    const loadGSAPAndScrollTrigger = async () => {
      const { gsap } = await import("gsap");
      const { ScrollTrigger } = await import("gsap/ScrollTrigger");

      gsap.registerPlugin(ScrollTrigger);

      const boxes = gsap.utils.toArray(".card");
      boxes.forEach((box) => {
        gsap.to(box, {
          opacity: 1,
          ease: "slow",
          scrollTrigger: {
            trigger: box,
            start: "top bottom",
            end: "top 20%",
            scrub: true,
            immediateRender: true,
          },
        });
      });
    };

    loadGSAPAndScrollTrigger();
  }, []);

  return (
    <div style={{ paddingTop: "8vh" }}>
      <h2 className="title" style={{ marginBottom: "4rem" }}>
        Projects
      </h2>
      <div className="grid">
        <a href="https://blog.apartment304.com/" className="card">
          <div className="img-box">
            <img src={project1} alt="Jacqueline Rhoda Photography Website" />
            <div className="overlay-text">Click To Open Project</div>
          </div>
          <div className="card-caption-box">
            <div className="card-caption1">Apartment 304 Blog</div>
            <div className="card-caption2">
              This website showcases blogs written by members of a tech company. New posts are automatically brought to the top of the website when published by an employee at APT304. The website also allows users to easily click and read into any article that catches their attention.
            </div>
            <div className="card-caption3">
            HTML &nbsp;|&nbsp; CSS &nbsp;|&nbsp; Hugo Framework
            </div>
          </div>
        </a>
        <a href="http://keithgrant.net/" className="card">
          <div className="img-box">
              <img src={project2} alt="Keith Grant Software Developer Website" />
              <div className="overlay-text">Click To Open Project</div>
          </div>
          <div className="card-caption-box">
            <div className="card-caption1">Software Developer Portfolio</div>
            <div className="card-caption2">
              This modern website displays a software developer's proficiency in
              diverse programming languages and technologies. Its navigation bar
              enhances the user experience by highlighting the current page, and
              a resume feature provides employers with easy access to the
              developer's qualifications.
            </div>
            <div className="card-caption3">
              ReactJS &nbsp;|&nbsp; HTML &nbsp;|&nbsp; CSS
            </div>
          </div>
        </a>
        <a href="https://www.jacqueline-uiux.com/" className="card">
          <div className="img-box">
            <img src={project6} alt="UI-UX Designer Website" />
            <div className="overlay-text">Click To Open Project</div>
          </div>
          <div className="card-caption-box">
            <div className="card-caption1">UI UX Designer Portfolio</div>
            <div className="card-caption2">
              This website showcases the work of a UI/UX designer. The website
              highlights their work through a project carousel and a case study
              page that illustrates their methodology. This effectively
              showcases their ability to deliver a project from concept to
              completion and communicate design decisions to stakeholders.
            </div>
            <div className="card-caption3">
              ReactJS &nbsp;|&nbsp; HTML &nbsp;|&nbsp; CSS{" "}
            </div>
          </div>
        </a>
        <a href="https://362-group-project.vercel.app/" className="card">
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="img-box"
          >
            <img
              style={{ marginBottom: "0.5rem" }}
              src={project3a}
              alt="Cash Register Web App"
            />
            <img
              style={{ borderRadius: "0" }}
              src={project3b}
              alt="Cash Register Web App"
            />
            <div className="overlay-text">Click To Open Project</div>
          </div>
          <div className="card-caption-box">
            <div className="card-caption1">Virtual Grocery Website</div>
            <div className="card-caption2">
              This is a shopping cart website completed at CSUF. The goal was to
              create a web app that allows users to select grocery items and
              calculate a total cost, including tax, for their selected items.
              Notably, it allows users to save an incomplete order and resume it
              later with an 8-character ID, avoiding the need to start over.
            </div>
            <div className="card-caption3">
              ReactJS &nbsp;|&nbsp; HTML &nbsp;|&nbsp; CSS
            </div>
          </div>
        </a>
        <div className="card">
        <a
          href="https://github.com/clairerhoda/CSUF-Room-Reservation"
          style={{textDecoration: "none"}}
        >
          <div className="img-box">
            <img src={project4} alt="CSUF Room Reservation Website" />
            <div className="overlay-text">Click To Open Project</div>
          </div>
          <div className="card-caption-box">
            <div className="card-caption1">Study Room Reservation System</div>
            <div className="card-caption2">
              This mock website, created as a project at CSUF, enables library
              users to reserve study rooms. Users can specify the number of
              students and preferred date and time, and the website retrieves
              availability information from a database to determine which rooms
              meet their criteria.
            </div>
            <div className="card-caption3">
              HTML &nbsp;|&nbsp; CSS &nbsp;|&nbsp; JavaScript &nbsp;|&nbsp;
              PostgreSQL
            </div>
          </div>
        </a>
        </div>
        
        <div className="card" style={{cursor:"auto"}} id="non-click-proj">
            <div style={{display:'flex'}} className="img-box">
              <img
                style={{
                  width: "48%",
                  marginRight: "4%",
                  borderRadius: "5px 0 0 0",
                }}
                src={project5a}
                alt="TextRSVP Application"
              />
              <img
                style={{ width: "48%", borderRadius: "0 5px 0 0" }}
                src={project5b}
                alt="TextRSVP Application"
              />
            </div>
          <div className="card-caption-box">
            <div className="card-caption1">TextRSVP Application</div>
            <div className="card-caption2">
              TextRSVP is a mobile app for event planning and management, where
              users can create events with a title, date, and RSVP deadline via
              the intuitive interface. The app uses Twilio to enable inviting
              guests and tracking RSVP statuses by sending and receiving text
              messages within the app.
            </div>
            <div className="card-caption3">
              Flutter &nbsp;|&nbsp; Dart &nbsp;|&nbsp; Golang &nbsp;|&nbsp;
              PostgreSQL
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
