import "./App.css";
import React, { useState, useEffect, useRef } from 'react';

import NavigationBar from "./components/NavigationBar";
import Header from "./components/Header";
import Projects from "./components/Projects";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {
  const [selectedOption, setSelectedOption] = useState("");

  const projectSection = useRef(null);
  const aboutSection = useRef(null);
  const contactSection = useRef(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (selectedOption === "Projects") {
      window.scrollTo({ top: projectSection.current.offsetTop, behavior: 'smooth', })
    } else if (selectedOption === "About") {
      window.scrollTo({ top: aboutSection.current.offsetTop, behavior: 'smooth', })
    } else if (selectedOption === "Contact Me") {
      window.scrollTo({ top: contactSection.current.offsetTop, behavior: 'smooth', })
    }
    setSelectedOption("") // reset
  }, [selectedOption]);


  return (
    <div>
      {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
      <div className="circle-box">
        <svg id="circle-rotate" viewBox="0 0 512 512">
          <path d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z" />
        </svg>
      </div>
      <NavigationBar onOptionSelect={handleOptionSelect}/>
      <Header onOptionSelect={handleOptionSelect}/>
      <div ref={projectSection}>
        <Projects />
      </div>
      <div ref={aboutSection}>
        <About />
      </div>
      <div ref={contactSection}>
        <Contact />
      </div>
    </div>
  );
}

export default App;
