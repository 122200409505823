import React, { useState, useLayoutEffect } from 'react';
import "../css/Contact.css";
import github from "../images/githublogo.png";
import email from "../images/email.png";
import linkedin from "../images/linkedinlogo.png";
import { useForm } from "react-hook-form";

import { init, sendForm } from "emailjs-com";
import { gsap } from "gsap";

init("user_uFxU4luDAGoylgiVtktkW");

const Contact = () => {
  useLayoutEffect(() => {
    const loadScrollTrigger = async () => {
      const { ScrollTrigger } = await import("gsap/ScrollTrigger");
      gsap.registerPlugin(ScrollTrigger);
      gsap.to(".box", {
        ease: "slow",
        opacity: 1,
        scrollTrigger: {
          trigger: ".box",
          start: "top bottom",
          end: "bottom 70%",
          scrub: true
        }
      });
    };

    loadScrollTrigger();
  }, []);
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  var [submitText, setSubmitText] = useState("Send Message");
  var [transform, setTransform] = useState("184px");
  var [padding, setPadding] = useState("38px");

  const onSubmit = () => {
    setSubmitText((submitText = "Sending..."));
    const form = document.querySelector("form");

    try {
    sendForm("service_qysegn3", "template_xy1hjy6", "#form-info").then(
      function () {
        setSubmitText("Message sent. Thank you\nfor reaching out.")
        setTransform("300px");
        setPadding("90px");
        form.reset();
      },
      function () {
        setSubmitText("An error has occured.\nPlease use the email icon if\nthe problem persists.");
        setTransform("300px");
        setPadding("120px");
      }
    );
    } catch (error) {
      setSubmitText("An error has occured.\nPlease use the email icon if\nthe problem persists.");
      setTransform("300px")
      setPadding("120px");
    }
    setTimeout(() => {
      setSubmitText((submitText = "Send Message"));
      setTransform("184px");
      setPadding("38px");
    }, 5000);
  };

  const message = watch("message") || "";
  const messageCharsLeft = 320 - message.length;

  return (
    <div style={{paddingTop: '25vh', marginBottom: '30vh'}}>
      <h2 className="title">Contact</h2>
      <div className="contact-alignment" >
      <form id="form-info" className="box" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "48%" }}>
            <div className="input-header">
              <label className="input-title">Full Name</label>
              {errors.fullName && (
                <div className="required-error">*Required</div>
              )}
            </div>
            <input
              type="text"
              id="input-1"
              autoComplete="off"
              {...register("fullName", { required: true })}
            />
          </div>
          <div style={{ width: "48%" }}>
            <div className="input-header">
              <label className="input-title">Email</label>
              {errors.email && <div className="required-error">*Required</div>}
            </div>
            <input
              id="input-2"
              autoComplete="off"
              type="email"
              {...register("email", { required: true })}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <div>
            <div className="input-header">
              <label className="input-title">Message</label>
              {errors.message && (
                <div className="required-error">*Required</div>
              )}
            </div>
            <textarea
              type="text"
              id="input-3"
              autoComplete="off"
              maxLength="320"
              {...register("message", { required: true })}
            />
            <div
              style={{
                textAlign: "end",
                fontSize: "12px",
                color:
                  messageCharsLeft === 0
                    ? "rgb(205, 84, 84)"
                    : "rgb(255, 255, 255)",
              }}
              className="message-chars-left"
            >
              {messageCharsLeft}
            </div>
          </div>
          <input
          style={{width: transform, height: padding}}
            id="submit-button"
            type="submit"
            value={submitText}
          />
        </div>
      </form>
          <div className="contact-buttons box">
            <a className="icon" href="mailto:clairehrhoda@gmail.com">
              <img style={{width: '2.2rem'}} src={email} alt="Email Symbol" />
            </a>
            <a className="icon" href="https://github.com/clairerhoda">
              <img style={{width: '2.2rem'}} src={github} alt="GitHub Logo" />
            </a>
            <a className="icon" href="https://www.linkedin.com/in/claire-rhoda-96aa1211a/">
              <img style={{width: '2.2rem'}} src={linkedin} alt="LinkedIn Logo" />
            </a>
          </div>
      </div>
    </div>
  );
};

export default Contact;
